<template>
  <v-container>
    <v-toolbar>
      <v-slide-group class="pt-2">
        <v-slide-item>
          <v-btn color="primary" @click="newPlan">Nouveau plan</v-btn>
        </v-slide-item>
        <v-slide-item>
          <site-tree-selector nomargin  v-model="sites"></site-tree-selector>
        </v-slide-item>
        <v-slide-item>
          <v-select label="Catégorie" :items="domaines" item-value="id" item-text="name" v-model="domaine"></v-select>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>
    <v-sheet height="400" width="100%" v-if="plan_loading_status !== 'LOADED'">
      <v-row no-gutters justify="center" align-content="center">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </v-row>
    </v-sheet>
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
        <tr>
          <th>Nom du plan</th>
          <th>Site représenté</th>
          <th>Catégorie</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="plan in filtered_plans" :key="plan.id">
          <td>
            <router-link :to="'/plans/'+ plan.id">{{ plan.name }}</router-link>
          </td>
          <td>{{ plan.site_name }}</td>
          <td>{{ plan.category.name }}</td>
          <td>
            <DeletePlanDialog :plan="plan"/>
            <v-btn icon @click="editPlan(plan)">
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import {DrawerMixin} from "@/mixins/util/DrawerMixin";
import PlanForm from "@/components/plans/PlanForm";
import SiteTreeSelector from "@/views/SiteTreeSelector";
import DeletePlanDialog from "@/views/DeletePlanDialog";

export default {
  name: "PlanList",
  components: {DeletePlanDialog, SiteTreeSelector},
  mixins: [DrawerMixin],
  async mounted() {
    if (this.$store.getters["plans/loading_status"] !== "LOADED") {
      await this.$store.dispatch("plans/fetchPlans");
    }
    if (this.$store.getters["sites/allSites"].length === 0) {
      await this.$store.dispatch("sites/fetchSites");
    }
    if (this.$store.getters["categories/allCategories"].length === 0) {
      await this.$store.dispatch("categories/fetchCategories");
    }
  },
  data() {
    return {
      sites: [],
      domaine: null,
      deleting: false,
    }
  },
  computed: {
    plans() {
      return this.$store.getters["plans/plans"];
    },
    filtered_plans() {
      return this.plans
          .filter((plan) => this.sites.length === 0 || this.sites.includes("") ? true : this.sites.includes(plan.site))
          .filter((plan) => this.domaine !== null ? plan.category.id === this.domaine : true);
    },
    plan_loading_status() {
      return this.$store.getters["plans/loading_status"];
    },
    domaines() {
      return [{id: null, name: "Tout les domaines"},...this.$store.getters["categories/domaines"]];
    }
  },
  methods: {
    newPlan() {
      this.openDrawerDialog(PlanForm, {"operation": "create", key: 0, plan: null});
    },
    editPlan(plan) {
      this.openDrawerDialog(PlanForm, {"operation": "update", key: plan.id, plan});
    }
  }
}
</script>

